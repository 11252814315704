import React, { useEffect, useState } from "react"
import Layout from "../Layout"
import Hero from "../Hero"
import Breadcrumb from "../Breadcrumb"
import Searcher from "../Searcher"
import TitleBlock from "../TitleBlock"
import Calendar from "../Calendar"
import Schedule from "../Schedule"

import Pagination from "../Pagination"
import Event from "../Event"
import { useAxiosClient } from "../../service/useAxiosClient"
import { Fragment } from "react"
import TitlePage from "../TitlePage"
import { navigate } from "@reach/router"
import TopicalityMenu from "../TopicalityPage/TopicalityMenu"
import { searchT } from "../../commons/commons"

export default function ServicesAndActivitesPage({
  params = {},
  pageContext = {},
  location,
}) {
  const ctypk = pageContext.ctypk || params.ctypk
  const id = parseInt(ctypk)
  const [activitiesCategories, setActivitiesCategories] = useState([])
  const [activities, setActivities] = useState([])
  const [dates, setDates] = useState({})
  const [dateSelected, setDateSelected] = useState()
  const [lastActivities, setLastActivities] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [title, setTitle] = useState("")

  const [, getCategories] = useAxiosClient(
    {
      url: `/activities/categories/web/`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getActivities] = useAxiosClient(
    {
      url: `/activities/web/calendar/?category=${id}`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getDates] = useAxiosClient(
    {
      url: `/activities/web/calendar/dates/?category=${id}`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getLastActivity] = useAxiosClient({
    url: `/activities/web/last/`,
    method: "GET",
    params: {
      category: id,
      page: currentPage,
    },
  })

  const getListItems = () =>
    getLastActivity().then(({ data }) => {
      if (data) {
        setLastActivities(data.items)
        setTotalPages(data.total_pages)
      }
    })

  useEffect(() => {
    getCategories().then(({ data }) => {
      if (data && data.length) {
        setActivitiesCategories(data)
        const categoryTitle =
          data.find(item => item.pk === parseInt(id))?.title || ""
        setTitle(categoryTitle)
      }
    })
    getActivities().then(({ data }) => data && setActivities(data))
    getDates().then(({ data }) => data && setDates(data))
    getListItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (currentPage) getListItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const [, triggerEventFiltered] = useAxiosClient({}, { manual: true })

  const handleSelectEvent = dateSelected => {
    setDateSelected(dateSelected)
  }

  useEffect(() => {
    triggerEventFiltered({
      url: `/activities/web/calendar/`,
      method: "GET",
      params: {
        date: dateSelected,
        category: id,
      },
    }).then(({ data }) => setActivities(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Servicios y actividades", to: "/servicios-y-actividades" },
    { label: title },
  ]

  const renderEvents = () => {
    return (
      lastActivities &&
      lastActivities.map((item, i) => {
        return (
          <Event
            date={item.description_dates_calendar}
            title={item.title}
            path={`/servicios-y-actividades/${item.category.pk}/${item.category.slug}/${item.pk}/${item.slug}`}
            subtitle={item.subtitle}
            key={`event-${i}`}
            colorBorder={item?.category_color}
            id={item?.id}
            categoryPk={id}
          />
        )
      })
    )
  }

  const getPage = page => {
    setCurrentPage(page)
  }

  const renderPagination = () => {
    if (totalPages <= 1) {
      return <div className="WebExam-wrapper">{renderEvents()}</div>
    }

    return (
      <Fragment>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          getPage={getPage}
        />
        <div className="WebExam-wrapper">{renderEvents()}</div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          getPage={getPage}
        />
      </Fragment>
    )
  }

  const getCategory = data => {
    navigate(`/servicios-y-actividades/${data.pk}/${data.slug}`)
  }

  const handleSearchBar = filters => {
    navigate(`/servicios-y-actividades/buscador/?${searchT(filters)}`, {
      state: { filters: filters },
    })
  }

  return (
    <Layout>
      <TitlePage title={title} url={location.pathname} />
      <Hero image="/imgs/servicios-actividades-ret.jpg" text={title} />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher
          onChangeFilter={handleSearchBar}
          selectCategory={activitiesCategories}
        />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <TopicalityMenu
              list={activitiesCategories}
              showTitle={false}
              className="LayoutTest-options"
              useGalleryItem={false}
              getCategory={getCategory}
              initialCategory={id}
              useAllData
            />
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <TitleBlock title={title} icon="far fa-calendar-alt" />
            <div className="row efpa-row">
              <div className="col-12 col-md-6 Topicality-col-9">
                <Calendar
                  dates={dates}
                  onChange={handleSelectEvent}
                  className="WebTest-calendar"
                />
              </div>
              <div className="col-12 col-md-6 Topicality-col-9">
                <Schedule
                  title="Próximas actividades"
                  scheduleList={activities}
                  types={activitiesCategories}
                  selectedDate={dateSelected}
                  classNameTypes="full"
                />
              </div>
            </div>
            <p className="WebExam-title">Actividades anteriores:</p>
            {renderPagination()}
          </div>
        </div>
      </div>
    </Layout>
  )
}
